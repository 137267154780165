define("discourse/plugins/discourse-authentication-validations/discourse/services/user-field-validations", ["exports", "@glimmer/tracking", "@ember/object", "@ember/runloop", "@ember/service"], function (_exports, _tracking, _object, _runloop, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let UserFieldValidations = _exports.default = (_class = class UserFieldValidations extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "site", _descriptor, this);
      _initializerDefineProperty(this, "totalCustomValidationFields", _descriptor2, this);
      _initializerDefineProperty(this, "userFields", _descriptor3, this);
      _defineProperty(this, "currentCustomValidationFieldCount", 0);
    }
    setValidation(userField, value) {
      this._bumpTotalCustomValidationFields();
      if (this.currentCustomValidationFieldCount === this.totalCustomValidationFields) {
        (0, _runloop.next)(() => this.crossCheckValidations(userField, value));
      }
    }
    crossCheckValidations(userField, value) {
      let shouldShow = userField.show_values.includes(value);
      if (value === null && userField.show_values.includes("null")) {
        shouldShow = true;
      }
      this._updateTargets(userField.target_user_field_ids, shouldShow);
    }
    _updateTargets(userFieldIds, shouldShow) {
      userFieldIds.forEach(id => {
        const userField = this.site.user_fields.find(field => field.id === id);
        const className = `user-field-${userField.name.toLowerCase().replace(/\s+/g, "-")}`;
        const userFieldElement = document.querySelector(`.${className}`);
        if (userFieldElement) {
          userFieldElement.style.display = shouldShow ? "" : "none";
        }
      });
    }
    _bumpTotalCustomValidationFields() {
      if (this.totalCustomValidationFields !== this.currentCustomValidationFieldCount) {
        this.currentCustomValidationFieldCount += 1;
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "totalCustomValidationFields", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "userFields", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setValidation", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setValidation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "crossCheckValidations", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "crossCheckValidations"), _class.prototype)), _class);
});